<template>
  <v-data-table
    multi-sort
    :show-select="isSelecteable"
    :headers="headers"
    :items="serverSide ? serverItems : clientItems"
    :item-key="itemKey"
    :loading="loading"
    :search="search"
    :options.sync="options"
    :value="value"
    v-bind="bindOptions"
    :show-expand="isExpand || isDescription || isExpandServer"
    :show-group-by="showGroupBy"
    @input="onInput"
    @item-expanded="itemExpanded"
    :single-expand="singleExpand"
    :hide-default-footer="hideDefaultFooter"
    @update:options="updateOptions"
  >
    <!-- expands -->
    <template
      v-slot:expanded-item="{ headers, item }"
      v-if="isExpand || isDescription"
    >
      <td :colspan="headers.length" class="my-3" v-if="isExpand">
        <v-data-table
          v-if="isExpand"
          multi-sort
          :headers="callPrepareSubHeaders(item)"
          :items="callPrepareSubItems(item)"
          :item-key="itemKey"
          :loading="loading"
          :search="search"
          :options.sync="options"
          :value="value"
          v-bind="bindOptions"
          hide-default-footer
        ></v-data-table>
      </td>
      <td :colspan="headers.length" v-if="isDescription">
        <div v-if="isDescription" v-html="callPrepareSubItemDesc(item)"></div>
      </td>
    </template>

    <template v-slot:expanded-item="{ headers, item }" v-if="isExpandServer">
      <td :colspan="headers.length">
        <app-table
          server-side
          :headers="expandServerHeaders"
          :model="model"
          :app="app"
          :hide-edit="hideEdit"
          :hide-delete="hideDelete"
          :loading="loading"
          :is-selecteable="false"
          :server-items="
            expandItems ? expandItems[item[subItemKey ? subItemKey : 'id']] : []
          "
          :server-items-length="
            expandItemsLength
              ? expandItemsLength[item[subItemKey ? subItemKey : 'id']]
              : 0
          "
          @edit="onExpandEdit"
          @view="onExpandView"
          @delete="onExpandDelete"
          @server="(opt) => onExpendedServer(item, opt)"
          :options.sync="expandedOptions[item[subItemKey ? subItemKey : 'id']]"
          :is-expand-server="true"
          :single-expand="true"
          :expand-server-headers="expandServerHeaders"
          :expand-items="expandItems"
          :expand-items-length="expandItemsLength"
          @item-expanded="expendedExpend"
          @expand-view="onExpandView"
        ></app-table>
      </td>
    </template>

    <!-- create button filter -->
    <template
      v-for="(header, index) in headers"
      v-slot:[`header.${header.value}`]="{}"
    >
      <span :key="index">
        {{ header.text }}
        <span v-if="!header.hideFilter">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="colorIconFilter(header.value)">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <template v-if="header.filterType === 'boolean'">
                <v-checkbox
                  v-model="textFilter[header.value]"
                  indeterminate
                  @change="
                    filters(
                      header.value,
                      header.mongo,
                      header.filterMode,
                      header.filterName,
                      header.isDate
                    )
                  "
                ></v-checkbox>
              </template>
              <template v-else>
                <v-text-field
                  class="pa-4"
                  type="text"
                  label="Enter the search term"
                  v-model="textFilter[header.value]"
                  :autofocus="true"
                  @input="
                    filters(
                      header.value,
                      header.mongo,
                      header.filterMode,
                      header.filterName,
                      header.isDate
                    )
                  "
                ></v-text-field>
              </template>
              <v-btn
                @click="
                  cleanFilter(
                    header.value,
                    header.mongo,
                    header.filterMode,
                    header.filterName
                  )
                "
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </span>
      </span>
    </template>

    <template
      v-for="header in headers"
      :slot="`item.${header.value}`"
      slot-scope="{ item }"
    >
      <!-- actions and actions_view-->
      <div
        v-if="header.value === 'actions' || header.value === 'actions_view'"
        :key="`actions-${header.value}`"
      >
        <v-icon
          v-if="!enableEdit && header.value === 'actions_view'"
          small
          @click="onView(item)"
        >
          mdi-open-in-new
        </v-icon>
        <v-icon v-if="enableEdit" small @click="onEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="enableDelete" small class="ml-2" @click="onDelete(item)">
          mdi-delete
        </v-icon>
      </div>

      <!-- link -->
      <v-icon
        v-else-if="header.link"
        color="primary"
        :key="`link-${header.value}`"
        @click="onLink(header, item)"
      >
        mdi-link
      </v-icon>

      <!-- check -->
      <v-icon
        v-else-if="header.check"
        :color="getValue(header.value, item) ? 'green' : 'grey lighten-2'"
        :key="`check-${header.value}`"
      >
        mdi-check
      </v-icon>

      <!-- chip -->
      <template v-else-if="header.chips">
        <v-chip
          v-for="value in item[header.value]"
          link
          :key="value.id"
          :color="header.chips.color || 'primary'"
          :to="header.chips.router ? header.chips.router(value) : null"
        >
          <v-icon v-if="header.chips.icon" left>{{ header.chips.icon }}</v-icon>
          {{
            header.chips.subValue
              ? value[header.chips.value || 'name'][header.chips.subValue]
              : value[header.chips.value || 'name']
          }}
        </v-chip>
      </template>

      <!-- Account Format -->
      <template v-else-if="header.accountFormat">
        {{ accountFormattedValue(getValue(header.value, item)) }}
      </template>

      <!-- DateTime Format -->
      <template v-else-if="header.dateTimeFormat">
        {{ dateTimeFormattedValue(getValue(header.value, item)) }}
      </template>

      <!-- Hours Format -->
      <template v-else-if="header.hourFormat">
        {{ hoursFormattedValue(getValue(header.value, item)) }}
      </template>

      <!-- Percent Format -->
      <template v-else-if="header.percentFormat">
        {{ percentFormattedValue(getValue(header.value, item)) }}
      </template>

      <!-- default -->
      <template v-else>
        {{ getValue(header.value, item) }}
      </template>
    </template>

    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot :name="name" v-bind="slotData" />
    </template>

    <!-- @slot default -->
    <slot />
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'debounce'
import { accounting, hoursMinutes } from '@utils/number-format'
import { format, parse } from 'date-fns'

export default {
  name: 'app-table',
  props: {
    /**
     * App used for validate permission
     */
    app: {
      type: String,
      required: true
    },
    /**
     * Model used for validate permission
     */
    model: {
      type: String,
      required: true
    },
    /**
     * value for selected items
     */
    value: {
      type: Array
    },
    /**
     * table headers
     */
    headers: {
      type: Array,
      required: true
    },
    /**
     * table items
     */
    serverItems: {
      type: Array,
      required: false
    },
    clientItems: {
      type: Array,
      required: false
    },

    /**
     * subtable items key
     */
    subItemKey: {
      type: String
    },
    /**
     * table items key to select
     */
    itemKey: {
      type: String,
      default: 'id'
    },
    /**
     * table items
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * table server items length
     */
    serverSide: {
      type: Boolean,
      default: false
    },
    /**
     * table server items length
     */
    serverItemsLength: {
      type: Number,
      default: null
    },
    /**
     * Show add button
     */
    hideEdit: {
      type: Boolean,
      default: false
    },
    /**
     * Show delete button
     */
    hideDelete: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    /**
     * Expand
     */
    isExpand: {
      type: Boolean,
      default: false
    },
    /**
     * Description
     */
    isDescription: {
      type: Boolean,
      default: false
    },
    /**
     * Selectable
     */
    isSelecteable: {
      type: Boolean,
      default: true
    },
    /**
     * Prepare sub header
     */
    prepareSubHeaders: {
      type: Function
    },
    /**
     * prepare sub Items
     */
    prepareSubItems: {
      type: Function
    },
    /**
     * bind options for table
     */
    binds: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    },
    showGroupBy: {
      type: Boolean,
      default: false
    },
    /**
     * Expandable (Server-side child items)
     */
    isExpandServer: {
      type: Boolean,
      default: false
    },
    /**
     * Table headers (Server-side child items)
     */
    expandServerHeaders: {
      type: Array,
      default: () => []
    },
    /**
     * Items (Server-side child items) should be a dictionary with the parent id (or subItemKey) as key
     */
    expandItems: {
      type: Object,
      default: () => {}
    },
    /**
     * Items total length (Server-side child items) should be a dictionary with the parent id (or subItemKey) as key
     */
    expandItemsLength: {
      type: Object,
      default: null
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    enableEdit() {
      return (
        !this.hideEdit &&
        this.permissions.includes(`${this.app}.change_${this.model}`)
      )
    },
    enableDelete() {
      return (
        !this.hideDelete &&
        this.permissions.includes(`${this.app}.delete_${this.model}`)
      )
    },
    bindOptions() {
      return this.serverSide
        ? { ...this.binds, serverItemsLength: this.serverItemsLength }
        : this.binds
    }
  },
  data() {
    return {
      // options: {},
      expandedOptions: {},
      subItems: [],
      // singleExpand: true,
      multiSearchOnServer: {},
      multiSearchOnClient: {},
      textFilter: {}
    }
  },
  watch: {
    $props: {
      handler() {
        if (!this.serverSide && !this.clientItems) {
          console.error('clientItems cannot be empty when serverSide is false')
        }
      },
      immediate: true
    },
    options: {
      handler(newValue, oldValue) {
        this.serverSide && newValue !== oldValue && this.onServer()
      },
      deep: true
    },
    search(newValue, oldValue) {
      this.serverSide && newValue !== oldValue && this.onServer()
    }
  },
  methods: {
    callPrepareSubHeaders(item) {
      if (this.prepareSubHeaders) {
        return this.prepareSubHeaders(item)
      }
      return [
        { text: this.$t('fields.text'), value: 'text' },
        { text: this.$t('fields.value'), value: 'value' }
      ]
    },
    callPrepareSubItems(item) {
      if (this.prepareSubItems) {
        return this.prepareSubItems(item)
      }
      return Object.entries(item[this.subItemKey]).map(([text, value]) => ({
        text: text,
        value: value
      }))
    },
    callPrepareSubItemDesc(item) {
      return item[this.subItemKey]['description']
    },
    itemSlotName(header) {
      return `item.${header.value}`
    },
    getValue(keys, item) {
      return keys.split('.').reduce((acc, key) => acc?.[key], item) ?? ''
    },
    accountFormattedValue(item) {
      return accounting(item)
    },
    dateTimeFormattedValue(item) {
      if (!item) return ''
      try {
        const date = parse(item, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date())
        return format(date, 'yyyy-MM-dd HH:mm')
      } catch (error) {
        console.error('Error on parse date', error)
      }
      return ''
    },
    hoursFormattedValue(item) {
      return hoursMinutes(item)
    },
    percentFormattedValue(item) {
      if (item == null || item === '') return ''
      return `${accounting(item)}%`
    },
    onInput(value) {
      /**
       * Set input for v-model
       * @type {Event}
       */
      this.$emit('input', value)
    },
    onEdit(item) {
      /**
       * Passthrough click edit item
       * @type {Event}
       */
      this.$emit('edit', item)
    },
    onView(item) {
      /**
       * Passthrough click view item on actions_view
       * @type {Event}
       */
      this.$emit('view', item)
    },
    onDelete(item) {
      /**
       * Passthrough click delete item
       * @type {Event}
       */
      this.$emit('delete', [item])
    },
    onLink(header, item) {
      /**
       * Passthrough click delete item
       * @type {Event}
       */
      this.$emit('link', header, item)
    },
    itemExpanded({ item, value }) {
      /**
       * Passthrough click delete item
       * @type {Event}
       */
      this.$emit('item-expanded', item, value)
    },
    onExpandEdit(item) {
      /**
       * Passthrough click edit expanded item
       * @type {Event}
       */
      this.$emit('expand-edit', item)
    },
    onExpandView(item) {
      /**
       * Passthrough click view expanded item on actions_view
       * @type {Event}
       */
      this.$emit('expand-view', item)
    },
    onExpandDelete(item) {
      /**
       * Passthrough click delete expanded item
       * @type {Event}
       */
      this.$emit('expand-delete', [item])
    },
    isValidDate(dateString) {
      return /^\d{4}-\d{2}-\d{2}$/.test(dateString)
    },
    filters(header, mongo, filterMode, filterName, isDate) {
      if (this.serverSide) {
        if (mongo) {
          this.multiSearchOnServer[`mongo__${header}`] = this.textFilter[header]
        } else {
          filterName = filterName || `${header}`.replace(/\./g, '__')
          if (filterMode === 'exact') {
            this.multiSearchOnServer[filterName] = this.textFilter[header]
          } else {
            if (isDate) {
              if (this.isValidDate(this.textFilter[header])) {
                this.multiSearchOnServer[`${filterName}__contains`] =
                  this.textFilter[header]
              }
            } else {
              this.multiSearchOnServer[`${filterName}__contains`] =
                this.textFilter[header]
            }
          }
        }
        this.onServer()
      } else {
        this.multiSearchOnClient[header] = this.textFilter[header]
        this.onClient()
      }
    },
    cleanFilter(header, mongo, filterMode, filterName) {
      // this.textFilter[header] = ''
      delete this.textFilter[header]
      if (this.serverSide) {
        if (mongo) {
          this.multiSearchOnServer[`mongo__${header}`] = ''
        } else {
          filterName = filterName || `${header}`.replace(/\./g, '__')

          if (filterMode === 'exact') {
            delete this.multiSearchOnServer[filterName]
          } else {
            delete this.multiSearchOnServer[`${filterName}__contains`]
          }
        }
        this.onServer()
      } else {
        this.multiSearchOnClient[header] = ''
        this.onClient()
      }
    },
    colorIconFilter(header) {
      return this.textFilter[header] ? 'primary' : ''
    },
    reset() {
      this.options = {
        itemsPerPage: 10,
        page: 1
      }
      this.cleanFilter()
    },
    onServer: debounce(function () {
      /**
       * Passthrough server side
       * @type {Event}
       */
      this.$emit('server', {
        ...this.options,
        search: this.search,
        multiSearch: this.multiSearchOnServer
      })
    }, process.env.VUE_APP_DEBOUNCE),
    onClient: debounce(function () {
      /**
       * Passthrough client side
       * @type {Event}
       */
      this.$emit('client', this.multiSearchOnClient)
    }, process.env.VUE_APP_DEBOUNCE),
    onExpendedServer: debounce(function (item, options) {
      /**
       * Passthrough server side
       * @type {Event}
       */
      console.log(options)
      this.$emit('item-expanded', item, true, options)
      /*{
        ...this.expandedOptions[item[this.subItemKey ? this.subItemKey : 'id']],
        search: this.search,
        multiSearch: this.multiSearchOnServer
      })*/
    }, process.env.VUE_APP_DEBOUNCE),
    updateOptions(options) {
      // this.options = options
      this.$emit('update:options', options)
    },
    expendedExpend(item, value) {
      this.$emit('item-expanded', item, value)
    }
  },
  created() {
    if (!this.serverSide) {
      this.onServer()
    }
  }
}
</script>
